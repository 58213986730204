<template>
  <v-btn
    v-bind="$attrs"
    color="primary"
    class="form-button text-none"
    :class="{'form-button--secondary': secondary}"
    height="50"
    rounded
    :text="secondary"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    secondary: Boolean
  }
}
</script>

<style scoped lang="scss">
.form-button {
  font-weight: 600;
  font-size: 18px !important;
  letter-spacing: 0;

  &--secondary {
    border: 2px solid #0091cd;
  }
}
</style>
