import { db, functions } from '@/plugins/firebase'

export default {
  async editQuiz (quizId, quiz) {
    const quizRef = await db.collection('quiz').doc(quizId)
    await quizRef.update(quiz)
    const quizData = (await quizRef.get()).data()
    quizData.id = quizRef.id
    return quizData
  }
}

export const sendMailAfterAssignLevelToQuiz = (payload) => {
  const sendMailFunction = functions.httpsCallable('mails-sendMailAfterAssingSuggestedLevel')
  return sendMailFunction(payload)
}
