export default {
  editedStudent: null,
  pageBreak: 50,

  // Teachers
  editedTeacher: null,
  teachers: [],
  lastTeacher: null,
  existNextTeachers: false,

  // Classes
  editedClass: null,
  allClasses: [],
  classes: [],
  lastClass: null,
  existNextClasses: false,
  newClass: null,

  // Lesson
  classSelected: null,
  currentLesson: null,
  lessonLoading: false,

  // Students by lesson
  studentsByLesson: [],

  // All students of classes
  allStudents: [],
  lastStudent: null,
  existNextStudents: false
}
