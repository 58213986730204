<template>
  <v-app-bar app flat height="85" class="custom-app-bar">
    <home-logo class="mt-15" />
    <div class="header d-flex justify-space-between">
      <div class="ml-16">
        <span class="header__title2">The Spanish Learning Club - Class Schedule</span>
      </div>
      <div class="d-flex">
        <div class="header__input-container mr-15">
          <v-text-field v-show="$route.name === 'Home'" v-model="value" label="Search Quiz..." color="#BBB" outlined
                        hide-details @keyup="searchSurveys"
          />
        </div>
        <div>
          <v-avatar color="#c6cad6" size="56">
            <span class="header__text-avatar text-h6">{{ userInitials }}</span>
          </v-avatar>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon color="secondary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="35">
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="menu-item" @click="doLogout">
                Logout
              </v-list-item>
            </v-list>
          </v-menu>
          <!--          <v-btn icon color="secondary" @click="doLogout">-->
          <!--            <v-icon size="35">-->
          <!--              mdi-menu-down-->
          <!--            </v-icon>-->
          <!--          </v-btn>-->
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import HomeLogo from '@/components/home/HomeLogo'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Homeheader',
  components: {
    HomeLogo
  },
  data () {
    return {
      value: '',
      timeout: null
    }
  },
  computed: {
    ...mapState('quiz', ['requestAllQuizzes']),
    ...mapState('auth', ['myTeacherUser', 'user']),
    userInitials () {
      if (this.user && this.myTeacherUser && this.myTeacherUser.role === 'admin') {
        return 'GM'
      } else if (this.user && this.myTeacherUser && this.myTeacherUser.role === 'teacher') {
        return `${this.myTeacherUser.firstName ? this.myTeacherUser.firstName[0] : '-'}${this.myTeacherUser.lastName ? this.myTeacherUser.lastName[0] : '-'}`
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('quiz', ['setSearchSurveys', 'getAllQuizes', 'setRequestAllQuizzes']),
    async doLogout () {
      await this.logout()
      await this.$router.push({ name: 'Login' })
    },
    searchSurveys () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (this.requestAllQuizzes) {
          await this.getAllQuizes()
        }
        this.setRequestAllQuizzes(false)
        this.setSearchSurveys(this.value)
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 87%;
  height: 100%;
  align-items: center;
  background-color: #EFEFEF;
  margin-left: auto;
  border-radius: 0px 0px 20px 20px;

  &__title1 {
    color: #828e93;
    font-size: 20px;
    font-weight: 600;
  }

  &__title2 {
    color: #67757C;
    font-size: 20px;
    font-weight: 600;
  }

  &__input-container {
    width: 220px;
  }

  &__text-avatar {
    color: #243674;
  }
}

.custom-app-bar {
  background-color: white !important;
}

.menu-item {
  color: #67757C;
  font-size: 15px;
  font-weight: 600;
}
</style>
