<template>
  <v-navigation-drawer
    class="position-drawer"
    color="primary"
    dark
    permanent
    app
    width="185px"
  >
    <div class="mt-16">
      <router-link :is="item.inactive?'span': 'router-link'" v-for="(item, i) in items" :key="i"
                   :to="{name: item.routeName}" active-class="submenu--active"
      >
        <div class="text-center d-flex flex-column py-4 submenu" :class="{'submenu--inactive': item.inactive}">
          <v-icon size="30">
            {{ item.icon }}
          </v-icon>
          <span class="text-subtitle-1">{{ item.title }}</span>
        </div>
      </router-link>
    </div>
  </v-navigation-drawer>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'HomeDrawer',
  data () {
    return {
      items: [
        { title: 'Users', icon: 'mdi-account', routeName: 'Students' },
        { title: 'Quiz', icon: 'mdi-dots-grid', routeName: 'Home', inactive: false },
        { title: 'Horarios', icon: 'mdi-file-edit-outline', routeName: 'Schedule' },
        { title: 'Material', icon: 'mdi-chart-line', routeName: 'Materials' },
        { title: 'Reels', icon: 'mdi-video-outline', routeName: 'Reels', inactive: true },
        { title: 'Settings', icon: 'mdi-cog-outline', routeName: 'Settings', inactive: true }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser'])
  },
  watch: {
    myTeacherUser () {
      this.items[1].inactive = this.myTeacherUser.role === 'teacher'
    }
  },
  mounted () {
    this.items[1].inactive = this.myTeacherUser.role === 'teacher'
  }
}
</script>

<style lang="scss" scoped>
.position-drawer {
  top: 120px !important;
  height: calc(100vh - 120px);
}

.submenu {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 10px;
  color: white;

  &--inactive {
    opacity: 0.5;
  }

  &--active ::after {
    content: '';
    height: 40px;
    width: 5px;
    background-color: white;
    position: absolute;
    left: 0;
  }
}
</style>
