import { db, quizRef } from '@/plugins/firebase'
import { doc, updateDoc, onSnapshot } from 'firebase/firestore'
import quizService from '@/services/quizService'

export default {
  getQuizzes: async ({ state, commit }) => {
    const quizzes = []
    let snapShot = null
    if (!state.lastQuiz) {
      snapShot = await quizRef.limit(state.pageBreak).get()
    } else {
      snapShot = await quizRef.startAfter(state.lastQuiz).limit(state.pageBreak).get()
    }

    if (!snapShot.empty) {
      commit('SET_LAST_QUIZ', snapShot.docs[snapShot.docs.length - 1])
    }
    snapShot.forEach(doc => {
      const quiz = doc.data()
      quiz.id = doc.id
      quizzes.push(quiz)
    })
    commit('CONCAT_QUIZZES', quizzes)
    commit('SET_EXIST_QUIZZES', !snapShot.empty)
  },
  getAllQuizes: async ({ commit }) => {
    const quizzes = []
    const snapShot = await quizRef.get()
    snapShot.forEach(doc => {
      const quiz = doc.data()
      quiz.id = doc.id
      quizzes.push(quiz)
    })
    commit('SET_QUIZZES', quizzes)
  },
  getAnswers: async ({ commit }, quizId) => {
    const answers = []
    commit('TOGGLE_LOADER_ANSWER')
    const snapShot = await db.collection(`quiz/${quizId}/answers`).orderBy('questionIndex').get()
    snapShot.forEach(doc => answers.push(doc.data()))
    commit('SET_ANSWERS', answers)
    commit('TOGGLE_LOADER_ANSWER')
  },
  deleteQuiz: async ({ commit }, quiz) => {
    await db.collection('quiz').doc(quiz.id).delete()
    commit('REMOVE_QUIZ', quiz)
  },
  // Search
  setSearchSurveys: ({ commit }, value) => {
    commit('SET_SEARCH', value)
  },
  setRequestAllQuizzes: ({ commit }, value) => {
    commit('SET_REQUEST_ALL_QUIZZES', value)
  },
  updateQuizInformation: async ({ commit }, { quizId, payload }) => {
    const docRef = doc(db, 'quiz', quizId)
    await updateDoc(docRef, payload)

    onSnapshot(doc(db, 'quiz', quizId), (doc) => {
      commit('REPLACE_BY_ID', { id: doc.id, ...doc.data() })
    })
  },
  setQuizToAssign: ({ commit }, payload) => {
    commit('SET_QUIZ_TO_ASSIGN', payload)
  },

  editQuiz: async ({ state, commit, dispatch }, payload) => {
    const quizObject = await quizService.editQuiz(payload.quizId, payload.data)
    commit('REPLACE_BY_ID', quizObject)
    return quizObject
  }
}
