import { db, storage } from '@/plugins/firebase'

export default {
  async createMaterialRequest (material, teacherObject) {
    const payload = {
      teacher: {
        id: teacherObject.id,
        firstName: teacherObject.firstName,
        lastName: teacherObject.lastName,
        email: teacherObject.email
      },
      timestamp: new Date()
    }
    await db.collection(`materials/${material.id}/materialRequests`).add(payload)
  },

  async saveFilesOfBlocks (material) {
    if (material.blocks) {
      return await Promise.all(
        material.blocks.map(async (block, index) => {
          if (block.file && (block.type === 'image' || block.type === 'video' || block.type === 'file' || block.type === 'audio')) {
            const fileRef = await storage.ref(`materials/blocks/${block.type}/${material.name}-${block.type}-${index + 1}`)
            await fileRef.put(block.file)
            const fileUrl = await fileRef.getDownloadURL()
            delete block.file
            return {
              ...block,
              fileUrl
            }
          } else {
            return {
              ...block
            }
          }
        })
      )
    } else {
      return []
    }
  },

  async getMaterialById (id) {
    const materialRef = await db.collection('materials').doc(id).get()
    const materialObject = materialRef.data()
    materialObject.id = materialRef.id
    return materialObject
  },

  async getLastMaterialByOrder () {
    const materialsSnapshot = await db.collection('materials').orderBy('order', 'desc').limit().get()
    if (materialsSnapshot.empty) {
      return null
    }
    const materialObject = materialsSnapshot.docs[0].data()
    materialObject.id = materialsSnapshot.docs[0].id
    return materialObject
  },

  async validateUniqueMaterialOrder (order, excludedMaterialId = null) {
    const materialsRef = db.collection('materials').where('order', '==', order)
    const materialSnapshot = await materialsRef.get()
    if (excludedMaterialId) {
      const index = materialSnapshot.docs.findIndex(materialDoc => materialDoc.id === excludedMaterialId)
      return !!materialSnapshot.empty || index !== -1
    }
    return !!materialSnapshot.empty
  }
}
