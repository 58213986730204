export default {
  // Students
  students: [],
  lastStudent: null,
  existNextStudents: false,
  pageBreak: 50,
  searchValue: '',
  sortBy: 'createdAt',
  orientation: 'desc',
  requestAllStudents: true
}
