import { concat, removeItem, replaceById, set, unshift } from '@/utils/vuex'
import Vue from 'vue'

export default {
  SET_EDITED_MATERIAL: set('editedMaterial'),
  CONCAT_MATERIALS: concat('materials'),
  SET_MATERIALS: set('materials'),
  SET_LAST_MATERIAL: set('lastMaterial'),
  SET_EXIST_MATERIALS: set('existNextMaterials'),
  UNSHIFT_MATERIALS: unshift('materials'),
  REPLACE_MATERIAL_BY_ID: replaceById('materials'),
  REMOVE_MATERIAL: removeItem('materials'),

  // Material blocks
  ADD_MATERIAL_BLOCK: (state, payload) => (state.newMaterial.blocks = state.newMaterial.blocks.concat(payload)),
  REMOVE_MATERIAL_BLOCK: (state, payload) => (state.newMaterial.blocks = state.newMaterial.blocks.filter((block, index) => index !== payload)),
  REPLACE_MATERIAL_BLOCK_BY_INDEX: (state, payload) => {
    Vue.set(state.newMaterial.blocks, payload.index, payload.block)
  },
  SET_NEW_MATERIAL: set('newMaterial'),
  SET_MATERIAL_FORM_IS_MODIFIED: set('materialFormIsModified')
}
