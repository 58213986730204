<template>
  <base-modal v-model="showModal" width="680px" :show-dismiss-button="showDismissButton" @close="cancel">
    <template v-slot:body>
      <v-img width="250px" height="250px" class="mx-auto" :src="animationUrl" />
    </template>

    <template v-slot:subtitle>
      {{ message }}
    </template>
    <template v-slot:actions>
      <form-button width="307" elevation="0" class="mx-auto my-2" @click="confirm">
        {{ confirmButtonText }}
      </form-button>
    </template>
  </base-modal>
</template>

<script>
import baseModal from '@/components/modals/BaseModal'
import FormButton from '@/components/buttons/FormButton'

export default {
  name: 'AlertModal',
  components: {
    FormButton,
    baseModal
  },
  data () {
    return {
      showModal: false,
      resolve: null,
      message: '',
      type: 'check',
      confirmButtonText: '',
      showDismissButton: true
    }
  },
  computed: {
    animationUrl () {
      if (this.type === 'alert') {
        return '/img/alert-animation.gif'
      } else {
        return '/img/check-animation.gif'
      }
    }
  },
  methods: {
    show ({ message, type, confirmButtonText, showDismissButton }) {
      this.message = message || '¿Desea confirmar la acción?'
      this.type = type || 'check'
      this.confirmButtonText = confirmButtonText || 'Ok'
      this.showDismissButton = showDismissButton === undefined ? true : showDismissButton
      this.showModal = true

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    cancel () {
      this.resolve(false)
      this.showModal = false
      this.restoreDefaultValues()
    },
    confirm () {
      this.resolve(true)
      this.showModal = false
      this.restoreDefaultValues()
    },
    restoreDefaultValues () {
      this.message = '¿Desea confirmar la acción?'
      this.type = 'alert'
      this.confirmButtonText = 'Ok'
      this.showDismissButton = true
    }
  }
}
</script>

<style scoped>

</style>
