import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID
  // measurementId: process.env.VUE_APP_MEASUREMENTID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
export const auth = firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()
export const functions = firebase.functions()

// Quiz
export const quizRef = db.collection('quiz').orderBy('date', 'desc')
export const teachersRef = db.collection('teachers').orderBy('created_at', 'desc')
export const classStudentsRef = db.collectionGroup('classStudents').orderBy('created_at', 'desc')
export const classesRef = db.collection('classes').where('finished', '==', false).orderBy('created_at', 'desc')
export const materialsRef = db.collection('materials').orderBy('order').orderBy('created_at', 'desc')
