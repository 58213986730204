<template>
  <v-sheet color="white" elevation="10" rounded="xl">
    <v-img class="mx-auto" src="@/assets/tslc.png" width="150" />
  </v-sheet>
</template>

<script>
export default {
  name: 'HomeLogo'
}
</script>
