import { set, concat, toggle, removeItem, replaceById } from '@/utils/vuex'

export default {
  // quizz
  SET_QUIZZES: set('quizzes'),
  CONCAT_QUIZZES: concat('quizzes'),
  SET_LAST_QUIZ: set('lastQuiz'),
  SET_EXIST_QUIZZES: set('existNextQuizzes'),
  REMOVE_QUIZ: removeItem('quizzes'),
  REPLACE_BY_ID: replaceById('quizzes'),
  // answer
  SET_ANSWERS: set('answers'),
  TOGGLE_LOADER_ANSWER: toggle('loaderAnswers'),
  // search
  SET_SEARCH: set('searchSurveys'),
  SET_REQUEST_ALL_QUIZZES: set('requestAllQuizzes'),
  SET_QUIZ_TO_ASSIGN: set('quizToAssign')
}
