import { set, concat } from '@/utils/vuex'

export default {
  SET_STUDENTS: set('students'),
  SET_LAST_STUDENT: set('lastStudent'),
  SET_EXIST_STUDENTS: set('existNextStudents'),
  CONCAT_STUDENTS: concat('students'),
  SET_SEARCH_VALUE: set('searchValue'),
  SET_SORT_BY: set('sortBy'),
  SET_ORIENTATION: set('orientation'),
  SET_REQUEST_ALL_STUDENTS: set('requestAllStudents')
}
