<template>
  <div>
    <home-header />
    <home-drawer />
    <v-container fluid>
      <v-fade-transition>
        <router-view />
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>
import HomeDrawer from '@/components/home/HomeDrawer'
import HomeHeader from '@/components/home/HomeHeader'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    HomeDrawer,
    HomeHeader
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    async user () {
      await this.fetchMyUserTeacher()
    }
  },
  async mounted () {
    await this.fetchMyUserTeacher()
  },
  methods: {
    ...mapActions('auth', ['fetchMyUserTeacher'])
  }
}
</script>

<style scoped>

</style>
