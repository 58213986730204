import Vue from 'vue'

export const set = property => (state, payload) => (state[property] = payload)
export const concat = property => (state, payload) => (state[property] = state[property].concat(payload))
export const toggle = property => state => (state[property] = !state[property])
export const removeItem = property => (state, payload) => (state[property] = state[property].filter(item => item !== payload))
export const removeItemById = property => (state, payload) => (state[property] = state[property].filter(item => item.id !== payload.id))
export const replaceById = property => (state, payload) => {
  const index = state[property].findIndex((object) => object.id === payload.id)
  if (index === -1) {
    console.log('Elemento no encontrado')
  } else {
    Vue.set(state[property], index, payload)
  }
}
export const unshift = property => (state, payload) => state[property].unshift(payload)
export const removeItemByIndex = property => (state, payload) => (state[property] = state[property].filter((block, index) => index !== payload))
