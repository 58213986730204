export default {
  // Materials
  editedMaterial: null,
  materials: [],
  lastMaterial: null,
  existNextMaterials: false,
  pageBreak: 50,

  // Material blocks
  newMaterial: null,
  materialFormIsModified: false
}
