<template>
  <v-container fluid>
    <v-fade-transition>
      <router-view />
    </v-fade-transition>
  </v-container>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>

</style>
