import { concat, removeItem, removeItemById, replaceById, set, unshift } from '@/utils/vuex'
import Vue from 'vue'

export default {
  SET_EDITED_CLASS: set('editedClass'),
  SET_EDITED_STUDENT: set('editedStudent'),

  // Teachers
  SET_EDITED_TEACHER: set('editedTeacher'),
  CONCAT_TEACHERS: concat('teachers'),
  SET_TEACHERS: set('teachers'),
  SET_LAST_TEACHER: set('lastTeacher'),
  SET_EXIST_TEACHERS: set('existNextTeachers'),
  REMOVE_TEACHER: removeItem('teachers'),
  UNSHIFT_TEACHERS: unshift('teachers'),
  REPLACE_TEACHER_BY_ID: replaceById('teachers'),

  // Classes
  CONCAT_CLASSES: concat('classes'),
  SET_LAST_CLASS: set('lastClass'),
  SET_EXIST_CLASSES: set('existNextClasses'),
  UNSHIFT_CLASSES: unshift('classes'),
  REPLACE_CLASS_BY_ID: replaceById('classes'),
  REMOVE_CLASS: removeItem('classes'),

  // All classes
  SET_CLASS_SELECTED: set('classSelected'),
  SET_ALL_CLASSES: set('allClasses'),
  UNSHIFT_ALL_CLASSES: unshift('allClasses'),
  REPLACE_ALL_CLASS_BY_ID: replaceById('allClasses'),
  REMOVE_ALL_CLASS: removeItemById('allClasses'),

  // Create class mutations
  SET_NEW_CLASS: set('newClass'),
  ADD_WEEK_TO_NEW_CLASS: (state, payload) => (state.newClass.weeks = state.newClass.weeks.concat(payload)),
  REPLACE_CLASS_WEEK_BY_INDEX: (state, payload) => {
    Vue.set(state.newClass.weeks, payload.index, payload.week)
  },
  REMOVE_CLASS_WEEK_BY_INDEX: (state, index) => {
    state.newClass.weeks = state.newClass.weeks.filter((week, i) => index !== i)
  },

  // Lessons
  SET_CURRENT_LESSON: set('currentLesson'),
  LESSON_LOADING: set('lessonLoading'),

  // Students by lesson
  SET_STUDENTS_BY_LESSON: set('studentsByLesson'),
  UNSHIFT_STUDENT_BY_LESSON: unshift('studentsByLesson'),
  REPLACE_STUDENT_IN_LESSON_BY_ID: replaceById('studentsByLesson'),
  REMOVE_STUDENT_IN_LESSON_BY_ID: removeItemById('studentsByLesson'),

  // All Students of classes
  CONCAT_STUDENTS: concat('allStudents'),
  SET_LAST_STUDENT: set('lastStudent'),
  SET_EXIST_STUDENTS: set('existNextStudents'),
  SET_ALL_STUDENTS: set('allStudents')

}
