import { db } from '@/plugins/firebase'
import materialService from '@/services/materialService'

export default {
  setEditedMaterial: ({ commit }, payload) => {
    commit('SET_EDITED_MATERIAL', payload)
  },

  // Materials
  getMaterials: async ({ state, commit }, { sortBy, orientation }) => {
    const materials = []
    let snapShot = null
    const materialsRef = db.collection('materials').orderBy(sortBy, orientation)
    if (!state.lastMaterial) {
      snapShot = await materialsRef.limit(state.pageBreak).get()
    } else {
      snapShot = await materialsRef.startAfter(state.lastMaterial).limit(state.pageBreak).get()
    }

    if (!snapShot.empty) {
      commit('SET_LAST_MATERIAL', snapShot.docs[snapShot.docs.length - 1])
    }
    snapShot.forEach(doc => {
      const material = doc.data()
      material.id = doc.id
      materials.push(material)
    })
    commit('CONCAT_MATERIALS', materials)
    commit('SET_EXIST_MATERIALS', !snapShot.empty)
  },

  deleteMaterial: async ({ commit }, material) => {
    await db.collection('materials').doc(material.id).delete()
    commit('REMOVE_MATERIAL', material)
  },

  resetMaterials: async ({ commit }) => {
    commit('SET_LAST_MATERIAL', null)
    commit('SET_EXIST_MATERIALS', false)
    commit('SET_MATERIALS', [])
  },

  // Implementing materials block
  addNewMaterialBlock: async ({ commit }, payload) => {
    commit('ADD_MATERIAL_BLOCK', payload)
  },
  removeMaterialBlock: async ({ commit }, payload) => {
    commit('REMOVE_MATERIAL_BLOCK', payload)
  },
  replaceMaterialBlockByIndex: async ({ commit }, { block, index }) => {
    commit('REPLACE_MATERIAL_BLOCK_BY_INDEX', { block, index })
  },
  async addMaterial ({ commit }, payload) {
    payload.blocks = await materialService.saveFilesOfBlocks(payload)
    console.log(payload)
    const docRef = await db.collection('materials').add({
      name: payload.name,
      order: payload.order || 0,
      type: payload.type,
      level: payload.level,
      created_at: new Date(),
      blocks: payload.blocks
    })

    const material = (await docRef.get()).data()
    material.id = docRef.id

    commit('UNSHIFT_MATERIALS', material)

    return docRef
  },

  async editMaterial ({ commit }, payload) {
    payload.blocks = await materialService.saveFilesOfBlocks(payload)
    const docRef = await db.collection('materials').doc(payload.id)
    await docRef.set({
      name: payload.name,
      order: payload.order || 0,
      type: payload.type,
      level: payload.level,
      created_at: new Date(),
      blocks: payload.blocks
    }, { merge: true })

    const material = (await docRef.get()).data()
    material.id = docRef.id

    commit('REPLACE_MATERIAL_BY_ID', material)

    return docRef
  },

  async setNewMaterial ({ commit }, payload) {
    commit('SET_NEW_MATERIAL', payload)
  }
}
