import Vue from 'vue'
import VueRouter from 'vue-router'
import { ifAuthenticated, ifAuthenticatedIsAdmin, ifNotAuthenticated } from '@/router/guards'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/students',
    name: 'Students',
    component: () => import(/* webpackChunkName: "about" */ '../views/students/Index.vue'),
    beforeEnter: ifAuthenticatedIsAdmin,
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    beforeEnter: ifAuthenticatedIsAdmin,
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/schedule/Index'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/schedule/classes/add',
    name: 'AddClass',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/schedule/classes/Add.vue'),
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/schedule/classes/edit/:id',
    name: 'EditClass',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/schedule/classes/Edit.vue'),
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () => import(/* webpackChunkName: "materials" */ '../views/material/Index.vue'),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/materials/add',
    name: 'AddMaterial',
    component: () => import(/* webpackChunkName: "addMaterials" */ '../views/material/Add.vue'),
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/materials/edit/:id',
    name: 'EditMaterial',
    component: () => import(/* webpackChunkName: "editMaterials" */ '../views/material/Edit.vue'),
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/materials/preview',
    name: 'MaterialPreview',
    component: () => import(/* webpackChunkName: "previewMaterials" */ '../views/material/Preview.vue'),
    meta: {
      layout: 'dashboard'
    }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: ifNotAuthenticated,
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
//   const isAuthenticated = await store.dispatch('auth/ifAuthenticated')
//   if (requiresAuth) {
//     !isAuthenticated ? next({ name: 'Login' }) : next()
//   } else {
//     isAuthenticated ? next({ name: 'Home' }) : next()
//   }
//
//   const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
//   if (requiresAdmin) {
//     store.state.auth.myTeacherUser.role === 'teacher' ? next({ name: 'Schedule' }) : next()
//   } else {
//     next()
//   }
// })

export default router
