import { db } from '@/plugins/firebase'
import scheduleService from '@/services/scheduleService'

export default {
  setEditedStudent: async ({ state, commit }, payload) => {
    commit('SET_EDITED_STUDENT', payload)
  },

  // Teacher tab
  setEditedTeacher: async ({ state, commit }, payload) => {
    commit('SET_EDITED_TEACHER', payload)
  },
  getTeachers: async ({ state, commit }, { orderByField, orientation }) => {
    const teachers = []
    const teachersRef = await db.collection('teachers').orderBy(orderByField, orientation)
    let snapShot = null
    if (!state.lastTeacher) {
      snapShot = await teachersRef.limit(state.pageBreak).get()
    } else {
      snapShot = await teachersRef.startAfter(state.lastTeacher).limit(state.pageBreak).get()
    }

    if (!snapShot.empty) {
      commit('SET_LAST_TEACHER', snapShot.docs[snapShot.docs.length - 1])
    }
    snapShot.forEach(doc => {
      const teacher = doc.data()
      teacher.id = doc.id
      teacher.showPassword = false
      teachers.push(teacher)
    })
    commit('CONCAT_TEACHERS', teachers)
    commit('SET_EXIST_TEACHERS', !snapShot.empty)
  },
  addTeacher: async ({ state, commit }, payload) => {
    // Unique email validation
    const teacherRef = await db.collection('teachers').where('email', '==', payload.email).get()
    if (!teacherRef.empty) {
      const error = new Error('Ya existe un profesor con este email.')
      error.code = 'auth/email-already-in-use'
      throw error
    }
    // const userCredential = await auth.createUserWithEmailAndPassword(payload.email, payload.password)

    // Create teacher doc
    const docRef = await db.collection('teachers').add({
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: payload.password,
      created_at: new Date(),
      role: 'teacher'
    })
    const teacherObject = (await docRef.get()).data()
    teacherObject.showPassword = false
    teacherObject.id = docRef.id

    commit('UNSHIFT_TEACHERS', teacherObject)

    return docRef
  },
  editTeacher: async ({ state, commit }, payload) => {
    // Unique email validation
    const teacherRef = await db.collection('teachers')
      .where('email', '==', payload.email)
      .get()
    if (!teacherRef.empty) {
      teacherRef.forEach(doc => {
        if (doc.id !== payload.id) {
          const error = new Error('Ya existe un profesor con este email.')
          error.code = 'auth/email-already-in-use'
          throw error
        }
      })
    }

    // Update teacher doc
    const docRef = await db.collection('teachers').doc(payload.id)

    await docRef.update({
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: payload.password
    })

    const teacherObject = (await docRef.get()).data()
    teacherObject.showPassword = false
    teacherObject.id = docRef.id

    commit('REPLACE_TEACHER_BY_ID', teacherObject)

    return docRef
  },
  deleteTeacher: async ({ commit }, teacher) => {
    await db.collection('teachers').doc(teacher.id).delete()
    commit('REMOVE_TEACHER', teacher)
  },

  resetTeachers: async ({ commit }) => {
    commit('SET_LAST_TEACHER', null)
    commit('SET_EXIST_TEACHERS', false)
    commit('SET_TEACHERS', [])
  },

  // Classes actions
  getAllClasses: async ({ commit }) => {
    const classes = []
    const allClassesRef = db.collection('classes').orderBy('created_at', 'desc')
    const snapShot = await allClassesRef.get()
    snapShot.forEach((doc) => {
      const classItem = doc.data()
      classItem.id = doc.id
      classes.push(classItem)
    })
    commit('SET_ALL_CLASSES', classes)
  },

  async addClass ({ commit }, payload) {
    const classObject = await scheduleService.addClass(payload)
    commit('UNSHIFT_CLASSES', classObject)
    return classObject
  },
  editClass: async ({ state, commit, dispatch }, payload) => {
    const classObject = await scheduleService.editClass(payload.classId, payload.updatedClass)
    commit('SET_CLASS_SELECTED', classObject)
    commit('REPLACE_ALL_CLASS_BY_ID', classObject)
    return classObject
  },
  setNewClass: async ({ commit }, payload) => {
    commit('SET_NEW_CLASS', payload)
  },
  addWeekToNewClass: async ({ commit }, payload) => {
    commit('ADD_WEEK_TO_NEW_CLASS', payload)
  },
  replaceClassWeekByIndex: async ({ commit }, { week, index }) => {
    commit('REPLACE_CLASS_WEEK_BY_INDEX', { week, index })
  },
  removeWeekFromNewClassByIndex: async ({ commit }, index) => {
    commit('REMOVE_CLASS_WEEK_BY_INDEX', index)
  },

  deleteClass: async ({ commit }, classItem) => {
    await db.collection('classes').doc(classItem.id).delete()
    commit('REMOVE_ALL_CLASS', classItem)
  },

  setClassSelected: async ({ commit }, classItem) => {
    commit('SET_CLASS_SELECTED', classItem)
  },

  // Actions for schedule tab
  addStudentToClass: async ({ state, commit }, payload) => {
    const studentNestedObject = Object.assign({}, state.classSelected.students)
    if (payload.guest) {
      studentNestedObject[payload.email] = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        guest: payload.guest,
        active: true,
        createdAt: new Date()
      }
    } else {
      const { student } = await scheduleService.updateOrCreateStudent({
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email
      })
      studentNestedObject[student.id] = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        guest: payload.guest,
        active: true,
        createdAt: new Date()
      }

      const studentRef = await db.collection('students').doc(student.id)
      const newAssignedCoursesIds = student.assignedCoursesIds ? [...student.assignedCoursesIds] : []
      newAssignedCoursesIds.push(state.classSelected.id)
      await studentRef.update({
        assignedCoursesIds: newAssignedCoursesIds
      })
      // add new course to student courses subcollection
      const newLandingCourse = {
        created_at: state.classSelected.created_at,
        day: state.classSelected.day,
        name: state.classSelected.name,
        startHour: state.classSelected.startHour,
        endHour: state.classSelected.endHour,
        teacher: state.classSelected.teacher,
        level: state.classSelected.level,
        startDate: state.classSelected.startDate,
        nextSessionDate: state.classSelected.nextSessionDate,
        week: state.classSelected.week,
        originTimezone: state.classSelected.originTimezone
      }
      await db.collection(`students/${student.id}/myCourses`).doc(state.classSelected.id).set(newLandingCourse, { merge: true })
    }
    const updatedClass = await scheduleService.editClass(state.classSelected.id, {
      students: studentNestedObject,
      studentsIds: Object.keys(studentNestedObject)
    })

    commit('SET_CLASS_SELECTED', updatedClass)
    commit('REPLACE_ALL_CLASS_BY_ID', updatedClass)

    return updatedClass
  },

  deleteStudentFromClass: async ({ state, commit }, studentKey) => {
    const studentNestedObjectUpdated = Object.assign({}, state.classSelected.students)
    delete studentNestedObjectUpdated[studentKey]
    const updatedClass = await scheduleService.editClass(state.classSelected.id, {
      students: studentNestedObjectUpdated,
      studentsIds: Object.keys(studentNestedObjectUpdated)
    })
    commit('SET_CLASS_SELECTED', updatedClass)
    commit('REPLACE_ALL_CLASS_BY_ID', updatedClass)
    return updatedClass
  },

  //  Get all students
  fetchAllStudents: async ({ state, commit }, { orderByField, orientation }) => {
    const students = []
    const studentsRef = db.collection('students').orderBy(orderByField, orientation)
    let snapShot = null
    if (!state.lastStudent) {
      snapShot = await studentsRef.limit(state.pageBreak).get()
    } else {
      snapShot = await studentsRef.startAfter(state.lastStudent).limit(state.pageBreak).get()
    }

    if (!snapShot.empty) {
      commit('SET_LAST_STUDENT', snapShot.docs[snapShot.docs.length - 1])
    }
    snapShot.forEach(doc => {
      const student = doc.data()
      student.id = doc.id
      students.push(student)
    })
    commit('CONCAT_STUDENTS', students)
    commit('SET_EXIST_STUDENTS', !snapShot.empty)
  },

  resetAllStudents: async ({ commit }) => {
    commit('SET_LAST_STUDENT', null)
    commit('SET_EXIST_STUDENTS', false)
    commit('SET_ALL_STUDENTS', [])
  }
}
