import { auth, db, functions } from '@/plugins/firebase'

export default {
  async fetchClasses () {
    const classesRef = await db.collection('classes').get()
    const classes = []
    classesRef.forEach(doc => {
      const classObject = doc.data()
      classObject.id = doc.id
      classes.push(classObject)
    })
    return classes
  },

  async addClass (classObject) {
    const docRef = await db.collection('classes').add(classObject)
    const createdClass = (await docRef.get()).data()
    createdClass.id = docRef.id
    return createdClass
  },

  async getClassById (id) {
    const classRef = await db.collection('classes').doc(id).get()
    const classObject = classRef.data()
    classObject.id = classRef.id
    return classObject
  },
  async editClass (classId, classObject) {
    const editedClassRef = await db.collection('classes').doc(classId)
    await editedClassRef.update(classObject)
    const editedClassObject = (await editedClassRef.get()).data()
    editedClassObject.id = editedClassRef.id
    return editedClassObject
  },
  async getClassByTeacher (teacher) {
    const classRef = await db.collection('classes').where('teacher.id', '==', teacher.id).get()
    const classArray = []
    classRef.forEach(doc => {
      const classObject = doc.data()
      classObject.id = doc.id
      classArray.push(classObject)
    })
    return classArray
  },

  async getAllTeachers () {
    const teachersRef = await db.collection('teachers').get()
    const teachers = []
    teachersRef.forEach(doc => {
      const teacherObject = doc.data()
      teacherObject.id = doc.id
      teachers.push(teacherObject)
    })
    return teachers
  },

  async getAllLevels () {
    const levelsRef = await db.collection('levels').orderBy('order').get()
    const levels = []
    levelsRef.forEach(doc => {
      const levelObject = doc.data()
      levelObject.id = doc.id
      levels.push(levelObject)
    })
    return levels
  },

  async createLesson (lesson) {
    const lessonRef = await db.collection('lessons').add(lesson)
    const lessonData = (await lessonRef.get()).data()
    lessonData.id = lessonRef.id
    return lesson
  },

  async getLessonsByTeacher (teacherId) {
    const lessonsRef = await db.collection('lessons')
      .where('class.teacher.id', '==', teacherId)
      .orderBy('createdAt', 'desc').get()

    const lessons = []
    lessonsRef.forEach(doc => {
      const lesson = doc.data()
      lesson.id = doc.id
      lessons.push(lesson)
    })
    return lessons
  },

  async getMyTeacherUser () {
    const currentUser = await auth.currentUser
    if (currentUser) {
      const teacherRef = await db.collection('teachers').where('uid', '==', currentUser.uid).limit(1).get()
      if (!teacherRef.empty) {
        const teacher = teacherRef.docs[0].data()
        teacher.id = teacherRef.docs[0].id
        return teacher
      }
      return null
    } else {
      return null
    }
  },

  async getNextLevel (levelObject) {
    const currentLevelRef = await db.collection('levels').doc(levelObject.id).get()
    const levelRef = await db.collection('levels').orderBy('order').startAfter(currentLevelRef).get()
    let level = null
    if (!levelRef.empty) {
      level = levelRef.docs[0].data()
      level.id = levelRef.docs[0].id
    }
    return level
  },

  async deleteLesson (lesson) {
    await db.collection('lessons').doc(lesson.id).delete()
  },

  async getAllMaterials () {
    const materialsRef = await db.collection('materials').orderBy('order').get()
    const materials = []
    materialsRef.forEach(doc => {
      const materialObject = doc.data()
      materialObject.id = doc.id
      materials.push(materialObject)
    })
    return materials
  },

  // Schedule tab
  async createStudent (student) {
    const studentRef = await db.collection('students').add({
      ...student,
      createdAt: new Date(),
      assistance: [],
      assistanceCounter: 0
    })
    const studentObject = (await studentRef.get()).data()
    studentObject.id = studentRef.id
    return studentObject
  },
  async updateStudent (studentId, data) {
    const studentRef = await db.collection('students').doc(studentId)
    await studentRef.update(data)
    const studentObject = (await studentRef.get()).data()
    studentObject.id = studentRef.id
    return studentObject
  },
  async updateOrCreateStudent (student) {
    const studentsSnapshot = await db.collection('students').where('email', '==', student.email).limit(1).get()
    let created = false
    if (studentsSnapshot.empty) {
      created = true
      return { student: await this.createStudent(student), created }
    }
    return { student: await this.updateStudent(studentsSnapshot.docs[0].id, student), created }
  },

  sendMailWhenCreatingGuestUser (student, classObject) {
    const sendMailFunction = functions.httpsCallable('mails-sendMailWhenCreatingGuestUser')
    return sendMailFunction({ student, class: classObject })
  },

  async getStudent (studentId) {
    const studentSnapshot = await db.collection('students').doc(studentId).get()
    const student = studentSnapshot.data()
    student.id = studentSnapshot.id
    return student
  },

  async editStudent (studentId, student) {
    const studentRef = await db.collection('students').doc(studentId)
    await studentRef.update(student)
    const editedStudent = (await studentRef.get()).data()
    editedStudent.id = studentRef.id
    return editedStudent
  },

  async removeClassFromStudent (studentId, classId) {
    await db.collection(`students/${studentId}/myCourses`).doc(classId).delete()
    const studentObject = await this.getStudent(studentId)
    let assignedCoursesIds = studentObject.assignedCoursesIds || []
    assignedCoursesIds = assignedCoursesIds.filter((item) => item !== classId)
    await this.editStudent(studentId, { assignedCoursesIds })
  }
}
