<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>

    <v-card class="fill-height pa-4 base-modal">
      <div style="margin: auto;" :style="{'maxWidth': internCardMaxWidth}">
        <v-btn v-if="showDismissButton" class="base-modal__close-button pa-0" rounded elevation="0" max-width="45px"
               height="45px"
               min-width="45px" width="45px" @click="$emit('close')"
        >
          <v-img height="45px" width="45px" src="/img/close.svg" />
        </v-btn>

        <p class="base-modal__title my-5 px-1">
          <slot name="title" />
        </p>

        <v-container>
          <slot name="body" />
          <p class="base-modal__title">
            <slot name="subtitle" />
          </p>
        </v-container>

        <v-spacer />

        <v-card-actions>
          <slot name="actions" />
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  inheritAttrs: false,
  props: {
    showDismissButton: {
      type: Boolean,
      default () {
        return true
      }
    },
    internCardMaxWidth: {
      type: String,
      default () {
        return '520px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog::v-deep {
  border-radius: 20px !important;
}

.base-modal {
  border-radius: 20px !important;

  &__title {
    font-weight: 800 !important;
    font-size: 24px;
    text-align: center;
    color: #243674;
    margin-bottom: 0;
  }

  &__close-button {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

::v-deep .v-dialog {
  background-color: white;
}

::v-deep .v-dialog::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::v-deep .v-dialog::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::v-deep .v-dialog::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

::v-deep .v-dialog::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

::v-deep .v-dialog::-webkit-scrollbar-track {
  background: #ffff;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}

::v-deep .v-dialog::-webkit-scrollbar-track:hover,
::v-deep .v-dialog::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

</style>
