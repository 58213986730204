import Vue from 'vue'
import Vuex from 'vuex'
import { auth as fAuth } from '@/plugins/firebase'
// eslint-disable-next-line no-unused-vars
import moment from '@/plugins/moment'

// modules
import { auth } from '@/store/modules/auth'
import { quiz } from '@/store/modules/quiz'
import { schedule } from '@/store/modules/schedule'
import { materials } from '@/store/modules/materials'
import { students } from '@/store/modules/students'
import { dataState } from '@/plugins/vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    playingAudio: false,
    layout: 'dashboard'
  },
  mutations: {
    setPlayingAudio (state, playingAudio) {
      state.playingAudio = playingAudio
    },
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    }
  },
  actions: {
    // check current session
    checkUser: ({ dispatch, commit }) => {
      fAuth.onAuthStateChanged(user => {
        if (user) {
          commit('auth/SET_USER', user)
        } else {
          commit('auth/SET_USER', null)
          commit('quiz/SET_QUIZZES', [])
          commit('quiz/SET_ANSWERS', [])
        }
      })
    },
    updatePlayingAudio (context, playingAudio) {
      context.commit('setPlayingAudio', playingAudio)
    }

  },
  modules: {
    auth: {
      ...auth,
      namespaced: true
    },
    quiz: {
      ...quiz,
      namespaced: true
    },
    schedule: {
      ...schedule,
      namespaced: true
    },
    materials: {
      ...materials,
      namespaced: true
    },
    students: {
      ...students,
      namespaced: true
    }
  },
  plugins: [dataState]
})

export default store

// check current session
store.dispatch('checkUser')
