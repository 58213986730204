export default {
  // quiz
  quizzes: [],
  pageBreak: 50,
  lastQuiz: null,
  existNextQuizzes: false,
  // answers
  answers: [],
  loaderAnswers: false,
  // search
  searchSurveys: '',
  requestAllQuizzes: true,
  quizToAssign: null
}
