import store from '@/store'

export const ifAuthenticated = async (to, from, next) => {
  const isAuthenticated = await store.dispatch('auth/ifAuthenticated')
  if (isAuthenticated) {
    next()
    return
  }
  next({ name: 'Login' })
}

export const ifNotAuthenticated = async (to, from, next) => {
  const isAuthenticated = await store.dispatch('auth/ifAuthenticated')
  if (!isAuthenticated) {
    next()
    return
  }
  next({ name: 'Schedule' })
}

export const ifAuthenticatedIsAdmin = async (to, from, next) => {
  const isAuthenticated = await store.dispatch('auth/ifAuthenticated')
  if (isAuthenticated) {
    if (store.state.auth.myTeacherUser.role === 'teacher') {
      next({ name: 'Schedule' })
    } else {
      next()
    }
  } else {
    next({ name: 'Login' })
  }
}
