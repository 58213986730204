<template>
  <v-app>
    <component :is="layout" />
    <alert-modal ref="confirm" />
  </v-app>
</template>

<script>
import Dashboard from '@/layouts/Dashboard'
import Auth from '@/layouts/Auth'
import AlertModal from '@/components/modals/AlertModal'
const defaultLayout = 'auth'

export default {
  name: 'App',
  components: {
    Dashboard,
    AlertModal,
    Auth
  },
  computed: {
    layout () {
      return this.$route.meta.layout || defaultLayout
    }
  },
  mounted () {
    this.$root.$confirm = this.$refs.confirm.show
  }
}
</script>
