import { auth } from '@/plugins/firebase'
import scheduleService from '@/services/scheduleService'

export default {
  ifAuthenticated () {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(
        user => {
          unsubscribe()
          resolve(!!user)
        }
      )
    })
  },
  login: async ({ commit }, { email, password }) => {
    const { user } = await auth.signInWithEmailAndPassword(email, password)
    commit('SET_USER', user)
  },
  logout: async ({ commit }) => {
    await auth.signOut()
    commit('SET_USER', null)
  },

  fetchMyUserTeacher: async ({ commit }) => {
    let teacher = await scheduleService.getMyTeacherUser()
    if (!teacher) {
      teacher = { role: 'admin' }
    }
    commit('SET_MY_TEACHER_USER', teacher)
  }
}
