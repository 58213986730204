import { db } from '@/plugins/firebase'

export default {
  // Students
  getStudents: async ({ state, commit }, { sortBy, orientation }) => {
    const students = []
    let snapShot = null
    const studentsRef = db.collection('students').orderBy(sortBy, orientation)
    if (!state.lastStudent) {
      snapShot = await studentsRef.limit(state.pageBreak).get()
    } else {
      snapShot = await studentsRef.startAfter(state.lastStudent).limit(state.pageBreak).get()
    }

    if (!snapShot.empty) {
      commit('SET_LAST_STUDENT', snapShot.docs[snapShot.docs.length - 1])
    }
    snapShot.forEach(doc => {
      const student = doc.data()
      student.id = doc.id
      students.push(student)
    })
    commit('CONCAT_STUDENTS', students)
    commit('SET_EXIST_STUDENTS', !snapShot.empty)
  },

  getAllStudents: async ({ commit }, { sortBy, orientation }) => {
    const students = []
    const snapShot = await db.collection('students').orderBy(sortBy, orientation).get()
    snapShot.forEach(doc => {
      const student = doc.data()
      student.id = doc.id
      students.push(student)
    })
    commit('SET_STUDENTS', students)
  },

  resetStudents: async ({ commit }) => {
    commit('SET_LAST_STUDENT', null)
    commit('SET_EXIST_STUDENTS', false)
    commit('SET_STUDENTS', [])
  },
  setSortBy: async ({ commit }, payload) => {
    commit('SET_SORT_BY', payload)
  },
  setOrientation: async ({ commit }, payload) => {
    commit('SET_ORIENTATION', payload)
  },
  setRequestAllStudents: async ({ commit }, payload) => {
    commit('SET_REQUEST_ALL_STUDENTS', payload)
  },
  setSearchValue: async ({ commit }, payload) => {
    commit('SET_SEARCH_VALUE', payload)
  }
}
